<template>
  <main class="main" v-if="mapsPage">
    <div class="container">
      <div class="main__inner maps">
        <Banner />
        <section class="section section-pb48">
          <div class="filter__form-change-wrap">
            <form
              @submit.prevent="submitFilterForm"
              ref="filterForm"
              class="filter__form filter__form-change mayor__filter-form"
              autocomplete="off"
            >
              <div class="search filter">
                <div class="search__input">
                  <label for="posters_search" class="search__label">Поиск по названию карты</label>
                  <div class="search__input__wrap">
                    <input v-model="form.title.value" id="posters_search" type="text" maxlength="255" />
                    <button v-if="form.title.value" type="button" @click="resetFilterForm">
                      <CloseIcon />
                    </button>
                  </div>
                </div>
                <div v-if="categories && categories.length" class="filter__item">
                  <label>Темы</label>
                  <Multiselect
                    track-by="id"
                    label="title"
                    v-model="form.category_id.value"
                    :options="categories"
                    placeholder="Все"
                    selectLabel="Выбрать ↵"
                    deselectLabel="Удалить ↵"
                    selectedLabel="Выбрано"
                    :searchable="false"
                    :allow-empty="true"
                  >
                    <span slot="noOptions">Список пуст</span>
                  </Multiselect>
                </div>
                <div class="filter__item filter__date">
                  <label>Диапазон дат</label>
                  <div class="filter__date__inputs">
                    <div class="filter__date__input">
                      <flat-pickr
                        v-model="form.start_date.value"
                        :config="flatPickrConfig"
                        placeholder="дд.мм.гггг"
                      >
                      </flat-pickr>
                      <DatePickIcon />
                    </div>
                    <div class="filter__date__input">
                      <flat-pickr
                        v-model="form.end_date.value"
                        :config="flatPickrConfig"
                        placeholder="дд.мм.гггг"
                      >
                      </flat-pickr>
                      <DatePickIcon />
                    </div>
                  </div>
                </div>
                <div class="search__btn search__btn--with-clear">
                  <a @click.prevent="resetFilterForm" href="#" class="filter__reset"> Сбросить фильтр </a>
                  <button type="submit" class="btn-blue_dark" :disabled="loading">
                    <LoadingIndicator v-if="loading" title="Загрузка" />
                    <template v-else>Найти</template>
                  </button>
                </div>
              </div>
            </form>
            <a
              @click.prevent="changeFilterForm"
              class="filter__change-btn"
              :class="{ hide__btn: isFilterVisible }"
              href="#"
            >
              <span v-if="isFilterVisible">Скрыть фильтр</span>
              <span v-else>Поиск и фильтр по картам</span>
              <ArrowDownIcon />
            </a>
          </div>

          <LoadingIndicator v-if="loading" title="Загрузка" class="maps__item-text" />
          <div v-else-if="maps && maps.data && maps.data.length" class="maps__list">
            <router-link
              v-for="(item, index) in maps.data"
              :key="index"
              :to="{ name: 'map_open', params: { id: item.id } }"
              class="maps__item"
            >
              <div class="maps__item-img" v-if="item.head_img && item.head_img.img && item.head_img.img.url">
                <div class="image__container">
                  <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
                </div>
              </div>
              <div class="maps__item-iframe" v-html="item.frame" v-else-if="item.frame"></div>
              <div v-if="item.title" class="maps__item-title">{{ item.title }}</div>
              <div v-if="item.text" class="maps__item-description text__cut">{{ item.text }}</div>
              <div class="maps__item-bottom">
                <div class="maps__item-territory" v-if="item.category && item.category.title">
                  {{ item.category.title }}
                </div>
                <time v-if="item.date_publication" class="maps__item-date">{{
                  item.date_publication | formatDate
                }}</time>
              </div>
            </router-link>
          </div>
          <p v-else-if="$store.state.filterData" class="filter__no-results">
            <template v-if="$store.state.filterData.get('title')">
              По запросу "{{ $store.state.filterData.get("title") }}" ничего не найдено
            </template>
            <template v-else> По вашему запросу ничего не найдено </template>
          </p>
          <p v-else class="filter__no-results">Карты отсутствуют</p>
          <PaginationComponent
            v-if="page !== null && total !== null && total > maxOnPage"
            :total="Math.ceil(total / maxOnPage)"
            @change="paginate"
            :page="page"
          />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import Banner from "@/views/maps/components/Banner.vue";
import PaginationComponent from "@/components/Pagination.vue";
import axios from "axios";
import FlatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Multiselect from "vue-multiselect";
import DatePickIcon from "@/components/svg/DatePickIcon.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";

export default {
  name: "Maps",
  async asyncData({ store }) {
    await store.dispatch("GET_MAPS_PAGE");
  },
  data() {
    return {
      isFilterVisible: false,
      flatPickrConfig: {
        locale: Russian,
        enableTime: true,
        defaultHour: 0,
        defaultMinute: 0,
        dateFormat: "d.m.Y H:i",
        disableMobile: true,
      },
      page: 1,
      first: 9,
      loading: false,
      form: {
        title: {
          value: null,
          message: null,
        },
        start_date: {
          value: null,
          message: null,
        },
        end_date: {
          value: null,
          message: null,
        },
        category_id: {
          value: null,
          message: null,
        },
      },
    };
  },
  computed: {
    total() {
      return this.$store.state.mapsPage?.maps?.total;
    },
    maxOnPage() {
      return this.$store.state.mapsPage?.maps?.per_page;
    },
    mapsPage() {
      return this.$store.state.mapsPage;
    },
    maps() {
      return this.$store.state.mapsPage?.maps;
    },
    categories() {
      return this.$store.state.mapsPage?.categories;
    },
  },
  methods: {
    paginate(e) {
      this.page = e;
      let pageUrl = this.maps.first_page_url.slice(0, -1) + this.page;
      if (this.filtered) {
        axios
          .post(pageUrl, this.$store.state.filterData)
          .then((response) => {
            this.loading = false;
            this.$store.state.mapsPage.maps = response.data.maps;
            window.scrollTo(0, 0);
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      } else {
        axios
          .get(pageUrl)
          .then((response) => {
            this.loading = false;
            this.$store.state.mapsPage.maps = response.data.maps;
            window.scrollTo(0, 0);
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
    /**
     * Фильтр учреждений и организаций
     * @returns {Promise<void>}
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        this.page = 1;
        const fd = new FormData();
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        Object.keys(this.form).forEach((key) => {
          if (key === "category_id") {
            if (this.form[key].value) fd.append(key, this.form[key].value.id);
          } else {
            if (this.form[key].value !== null) fd.append(key, this.form[key].value);
          }
        });

        this.$store.state.filterData = fd;
        await this.$store
          .dispatch("POST_MAPS_FILTER", {
            data: fd,
          })
          .then((response) => {
            this.$store.state.mapsPage.maps = response.data.maps;
            this.filtered = true;
            this.loading = false;
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
    changeFilterForm() {
      let filter = this.$refs.filterForm;
      filter.style.display = this.isFilterVisible ? "none" : "flex";
      this.isFilterVisible = !this.isFilterVisible;
    },
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.$store.dispatch("GET_MAPS_PAGE");
      this.page = 1;
      this.filtered = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.filterData = null;
    this.$store.state.filterResponse = null;
    this.$store.state.filterList = [];
    this.$store.state.mapsPage = {};
    next();
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
  components: {
    Banner,
    PaginationComponent,
    FlatPickr,
    Multiselect,
    DatePickIcon,
    LoadingIndicator,
    CloseIcon,
    ArrowDownIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/maps/index.styl"
@import '~flatpickr/dist/flatpickr.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'
</style>
