<template>
  <div class="banner">
    <span class="title">Галерея карт</span>
    <div v-if="false" class="description">Сделайте свой вклад в развитие своего города, района, двора</div>
  </div>
</template>

<script>
export default {
  name: "Banner",
};
</script>
